@use "ag-grid-community/styles" as ag;
@import "/src/app/styles/variables";

$standard-ag-row-height: 42px;
$total-bottom-line: 1px solid $gray-dark;

@include ag.grid-styles(
  (
    theme: adap,
    extend-theme: alpine,
    alpine-active-color: $blue-light,
    border-color: $gray-light,
    header-background-color: $white,
    background-color: $white,
    font-family: GothamNarrowBook,
    // The designs call for 14px
    // but for some reason the internals of alpine theme do a plus 1 px
    font-size: 13px,
  )
);

.ag-theme-adap {
  --ag-even-row-background-color: $gray-light;
  --ag-selected-background-color: rgba($blue, 0.3);
  --ag-selected-background-color-hover: rgba($blue, 0.4);
  .ag-row-even {
    background-color: var(--ag-even-row-background-color);
  }

  .ag-row-hover {
    background-color: var(--ag-row-hover-color, transparent);
  }

  .ag-row-selected {
    background-color: var(--ag-selected-background-color);

    &.ag-row-hover {
      background-color: var(--ag-selected-background-color-hover);
    }
  }

  .ag-header {
    @include adap-text-GothamBookMedium(14);
  }

  &.ag-sticky {
    .ag-header {
      position: sticky;
      top: 0;
      z-index: 1;
    }
    .ag-floating-top {
      position: sticky;
      top: var(--floating-top-offset);
      z-index: 1;
    }
    .adap-ag-total-row-collapsed {
      border-bottom: $total-bottom-line;
    }
    .ag-root,
    .ag-root-wrapper {
      overflow: unset;
    }
    .ag-body-horizontal-scroll {
      position: sticky;
      bottom: 0;
      z-index: 1;
    }
  }

  .ag-horizontal-left-spacer,
  .ag-horizontal-right-spacer,
  .ag-body-horizontal-scroll-viewport {
    overflow-x: auto;
  }

  .ag-center-aligned-cell {
    text-align: center;
    justify-content: center;
  }

  .estimates-row-edit-button,
  .estimates-row-origin-chip {
    display: flex;
    justify-content: center;
    align-items: center;
    mat-icon {
      font-size: 24px;
    }
  }

  .mandatory-check-row-chip {
    display: flex;
    align-items: center;
  }

  .ag-clickable {
    cursor: pointer;
  }

  .ag-no-border {
    border: 0;
  }

  .ag-totals-row {
    border-bottom: $total-bottom-line;
    background-color: $white;
  }

  .ag-adap-number-of-deliverables-row {
    background-color: $gray-light;
  }

  .ag-adap-white-row {
    background-color: $white;
  }

  .ag-column-slim {
    padding-left: 5px;
    padding-right: 5px;
  }

  @mixin ag-estimate-cell {
    padding-left: 5px;
    padding-right: 5px;
    .ag-cell-wrapper {
      justify-content: end;
    }
  }

  .ag-estimate-cell {
    @include ag-estimate-cell;
  }

  .ag-estimate-total-cell {
    @include ag-estimate-cell;
    .ag-cell-wrapper {
      @include adap-text-GothamBold("normal");
    }
  }

  .ag-adap-total-estimates-first-row {
    @include ag-estimate-cell;
    .ag-cell-wrapper,
    .ag-cell-value {
      @include adap-text-GothamBold("normal");
      color: $blue;
    }
  }

  .ag-grid-estimates-title {
    .ag-cell-wrapper {
      // Needed because rest of the row is set to have
      // the values as flex-end
      justify-content: flex-start;
    }
  }

  .ag-grid-estimate-discipline-header {
    padding: 10px 5px;
    .ag-header-cell-label {
      align-items: center;
      justify-content: center;
    }
    .ag-header-cell-label .ag-header-cell-text {
      white-space: break-spaces;
      text-align: center;
    }
  }

  .ag-grid-cell-multiline {
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .ag-estimate-cell app-numeric-cell-editor {
    width: 100%;
  }

  .ag-grid-cell-middle-vertically {
    display: inline-flex;
    align-items: center;
  }

  .value-found-cell {
    background-color: rgba($blue-light, 0.3);
  }

  .ag-header-cell {
    &:has(.mat-mdc-checkbox) {
      padding-left: 8px;
    }
  }

  .ag-header-cell {
    .ag-icon-filter {
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover,
    .ag-filter-active {
      .ag-icon-filter {
        opacity: 1;
      }
    }
  }
}

.cell-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ag-metrics-value-recognition-action-items {
  text-align: right;
  @include adap-text-GothamBold("normal");
  color: $blue;
}
