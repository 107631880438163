$black: #000;
$black-dark: #212121;
$blue: #0066b2;
$blue-dark: #0b2d71;
$blue-light: #009dd9;
$blue-highlight: #e4f4fa;
$blue-text: #0b2d71;
$blue-shades-1: #111d45;
$blue-shades-2: #0b2d71;
$blue-shades-3: #064a91;
$blue-shades-4: #0066b2;
$blue-shades-5: #0081c5;
$blue-shades-6: #009dd9;
$blue-shades-7: #8fdbf9;
$gray-highlight: #ededee;
$gray-light: #f4f4f4;
$gray-light-2: #fbfcfb;
$gray-medium: #f7f7f7;
$gray: #c8c8c8;
$gray-dark: #6b6d6f;
$gray-primary: #8c8f93;
$orange: #da9c36;
$orange-light: #faab18;
$orange-medium: #e5601f;
$red: #e21836;
$burgundy: #97002e;
$crimson: #711b00;
$teal: #00708c;
$teal-shades-1: #00243e;
$teal-shades-2: #003653;
$teal-shades-3: #005370;
$teal-shades-4: #00708c;
$teal-shades-5: #0091a4;
$teal-shades-6: #00b2bd;
$teal-shades-7: #98e5e3;
$green-medium: #769231;
$green-light: #b2cc34;
$green-dark: #444b0d;
$separator-color: #cccccc;
$shadow-color: rgba(0, 0, 0, 0.2);
$card-shadow-color: #00000029;
$cyan-light: #ebf9fc;
$purple: #751269;
$purple-light: #ba3093;
$purple-dark: #3a0d36;

$white: #fff;

// form field disabled
$text-disabled: #4a4a4a;
$border-disabled: #6b6d6f;
$background-disabled: #e0e0e0;

// z-index order, register any z-index higher than 1

$z-index-checks-matching-manager: 2;
$z-index-deliverable-manager: 2;
$z-index-risk-assessment-focus-area: 2;
$z-index-navigation-tab-strip: 3;
$z-index-fullscreen-wrapper: 5;
$z-index-spinner-overlay: 100000;
