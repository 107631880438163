@import "./mixins.scss";
@import "./variables.scss";

@mixin adap-input-container-invalid {
  .adap-input-label {
    color: $burgundy !important;
  }

  input,
  textarea,
  mat-chip-list,
  mat-select,
  mat-chip-set,
  mat-chip-grid,
  mat-chip-listbox,
  app-user-search {
    @include adap-input-invalid;
  }

  .field-invalid-text {
    display: block;
    color: $burgundy;
  }
}

@mixin adap-input-invalid {
  &,
  &:focus {
    border-color: $burgundy;
  }

  &::placeholder,
  & .mat-mdc-select-placeholder {
    color: $burgundy !important;
    opacity: 0.7;
  }
}

.input-label-invalid {
  color: $burgundy;
}

.input-invalid-text {
  @include adap-text-GothamNarrowBook("small");
  color: $burgundy;
}

.field-required-star {
  color: $red;
}

@mixin field-invalid-text {
  display: none;
}

@mixin adap-mat-form-field-outline-invalid {
  border: 2px solid $burgundy;
  @include adap-mat-form-field-border;
}
