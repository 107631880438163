.toast-message a,
.toast-message label {
  color: $white;
}
.toast-message a:hover {
  color: $gray;
}
.toast-close-button {
  color: $white;
  text-shadow: 0 1px 0 $white;
  float: unset;
  font-weight: normal;
  position: absolute;
  right: 12px;
  top: 50%;
  font-size: 24px;
  transform: translateY(-50%);
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: $black;
}
.toast-container .ngx-toastr {
  box-shadow: 0 0 12px $gray-primary;
  white-space: pre-line;
  border-radius: 0;
  padding-right: 32px;
  &.toast-success {
    background-color: $teal;
    color: $white;
    background-image: url("/assets/cvx-icons/toast-success.svg");
  }
  &.toast-error {
    background-color: $burgundy;
    color: $white;
    background-image: url("/assets/cvx-icons/toast-error.svg");
  }
  &.toast-info {
    background-color: $gray-primary;
    color: $black;
    background-image: url("/assets/cvx-icons/toast-info.svg");
  }
  &.toast-warning {
    background-color: $orange;
    color: $black;
    background-image: url("/assets/cvx-icons/toast-warning.svg");
  }
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px $black;
}
