@import "ngx-toastr/toastr";
@import "./app/styles/reset";
@import "./app/styles/mixins";
@import "./app/styles/shared-per-roles-mixins.scss";
@import "./app/styles/text";
@import "./app/styles/variables";
@import "./app/styles/fonts";
@import "./app/styles/dialogs";
@import "./app/styles/theme";
@import "./app/styles/ag-grid";
@import "./app/styles/toast-messages";
@import "./app/styles/input-validation";
@import "~quill/dist/quill.snow.css";
@import "./app/styles/rich-text-editor.component.scss";

html {
  overflow: hidden;
  height: 100%;
  --mdc-checkbox-state-layer-size: 18px;
  --mdc-radio-state-layer-size: 18px;
}

.cdk-global-scrollblock {
  //this needs to be important, because cdk library changes style directly on element
  top: 0 !important;
  body {
    overflow: hidden;
  }
}

body {
  @include adap-text-GothamNarrowBook("normal");
  height: 100%;
  margin: 0;
  font-weight: 400;
  line-height: 1.5;
  overflow-y: scroll;
}

.adap-dialog-content {
  @include adap-dialog-content;
}

.adap-dialog-content-scroll {
  @include adap-dialog-content;
  flex: 1;
  overflow: auto;
}

.adap-title {
  @include adap-title;
}

.header-title {
  @include header-title;
}

.adap-dialog-title {
  @include adap-dialog-title;
}

.adap-btn-blank {
  @include adap-btn-blank;
}

.adap-btn-filled {
  @include adap-btn-filled;
}

.adap-btn-grid-cell {
  @include adap-btn-grid-cell;
}

.adap-btn-outline {
  @include adap-btn-outline;
}

.adap-btn-warning {
  @include adap-btn-warning;
}

.adap-btn-v-and-v {
  @include adap-btn-v-and-v;
}

.adap-btn-small {
  @include adap-btn-small;
}

.adap-btn-small-icon {
  @include adap-btn-small-icon;
}

.adap-v-and-v-status {
  color: $burgundy;
}

.adap-btn-ready {
  @include adap-btn-ready;
}

.adap-btn-observations {
  @include adap-btn-observations;
}

.adap-btn-actions {
  @include adap-btn-actions;
}

.adap-btn-reviewed {
  @include adap-btn-reviewed;
}

.adap-form-container {
  @include adap-form-container;
}

.adap-form-input {
  @include adap-form-input;
}

.adap-form-textarea {
  @include adap-form-textarea;
}

.adap-datepicker {
  @include adap-datepicker;
}

.adap-form-range-input {
  @include adap-form-range-input;
}

.adap-select {
  @include adap-select;
}

.adap-radio {
  @include adap-radio;
}

.adap-btn-toggle-group {
  @include adap-btn-toggle-group;
}

.adap-input-container-invalid {
  @include adap-input-container-invalid;
}

.mdc-checkbox .mat-mdc-checkbox-touch-target,
.mdc-radio .mat-mdc-radio-touch-target {
  display: none;
}

[fullscreenWrapper].fullscreen-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 8px;
  background: $white;
  animation: zoomIn 100ms ease;
  overflow: auto;
  z-index: $z-index-fullscreen-wrapper;
}

[fullscreenWrapper]:not(.fullscreen-wrapper):not(.fullscreen-initial) {
  animation: zoomOut 100ms ease;
}

.full-screen-toggle-button-no-padding {
  padding: 0;
}

@keyframes zoomIn {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
}

@keyframes zoomOut {
  from {
    transform: scale(1.05);
  }
  to {
    transform: scale(1);
  }
}

.adap-chip-v-and-v {
  @include adap-chip;
  @include adap-text-GothamBookMedium(14);
  color: $white;
  background-color: $burgundy;
  gap: 5px;
  width: 50px;
  height: 26px;
  text-align: center;
  padding: 4px 0;

  & mat-icon {
    @include mat-icon-size(18px);
  }
}

.adap-chip-v-and-v-ready-complete {
  @extend .adap-chip-v-and-v;
  color: $white;
}

.adap-chip-v-and-v-not-ready {
  @extend .adap-chip-v-and-v;
  color: $burgundy;
  background-color: $white;
  border-color: $burgundy;
  border: solid;
  border-width: 2px;
}

.adap-chip-discipline {
  @include adap-chip;
  color: $white;
  background-color: $blue;
}

.adap-chip-dismissed,
.adap-chip-returned {
  @include adap-chip;
  color: $white;
  background-color: $red;
}

.adap-color-white {
  color: $white;
}

.adap-color-blue-dark {
  color: $blue-dark;
}

.adap-background-color-blue {
  background-color: $blue;
}

.adap-background-color-blue-dark {
  background-color: $blue-dark;
}

.adap-background-color-green-dark {
  background-color: $green-dark;
}

.adap-background-color-green-medium {
  background-color: $green-medium;
}

.adap-background-color-teal {
  background-color: $teal;
}

.adap-background-color-blue-shades-1 {
  background-color: $blue-shades-1;
}

.adap-background-color-blue-shades-2 {
  background-color: $blue-shades-2;
}

.adap-background-color-blue-shades-3 {
  background-color: $blue-shades-3;
}

.adap-background-color-blue-shades-4 {
  background-color: $blue-shades-4;
}

.adap-background-color-blue-shades-5 {
  background-color: $blue-shades-5;
}

.adap-background-color-teal-shades-1 {
  background-color: $teal-shades-1;
}

.adap-background-color-teal-shades-2 {
  background-color: $teal-shades-2;
}

.adap-background-color-teal-shades-3 {
  background-color: $teal-shades-3;
}

.adap-background-color-teal-shades-4 {
  background-color: $teal-shades-4;
}

.adap-background-color-teal-shades-5 {
  background-color: $teal-shades-5;
}

.adap-background-color-orange-light {
  background-color: $orange-light;
}

.adap-background-color-burgundy {
  background-color: $burgundy;
}

.mat-expansion-indicator:after {
  color: $blue-dark;
}

.adap-link {
  color: $blue;
  display: flex;
  align-items: center;
  @include adap-text-GothamBold("normal");
  @include decoration-none;
  cursor: pointer;
  gap: 10px;
}

.adap-link-blue-light {
  color: $blue-light;
}

.mat-mdc-tooltip-surface {
  font-size: 16px !important;
}

b {
  font-weight: bold;
}

strong {
  font-weight: bold !important;
}

em {
  font-style: italic !important;
}

.mat-form-field-disabled .mat-mdc-form-field-infix .mat-mdc-input-element,
.mat-form-field-disabled .mat-mdc-form-field-infix .mat-mdc-select-value-text,
.mat-form-field-disabled .mat-mdc-form-field-infix .mat-mdc-select-placeholder {
  color: $text-disabled !important;
  background-color: $background-disabled !important;
}
