@import "/src/app/styles/variables";

.quill-editor-container {
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
  quill-editor {
    display: block;
  }

  border: 2px solid $gray;

  &:focus-within {
    border: 2px solid $blue !important;
  }
}

.editor-invalid {
  border: 2px solid $burgundy !important;
}
.editor-disabled {
  border: 2px solid $border-disabled !important;
}

.ql-editor {
  max-height: 250px;
  overflow: auto;
  @include adap-text-base("normal");
}

.ql-snow .ql-picker-label {
  padding-right: 18px;
}

.ql-snow .ql-picker.ql-font {
  width: 100%;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="0.75em"]::before,
.ql-snow
  .ql-picker.ql-size
  .ql-picker-label.ql-active[data-value="0.75em"]::before {
  content: "Small" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1em"]::before,
.ql-snow
  .ql-picker.ql-size
  .ql-picker-label.ql-active[data-value="1em"]::before {
  content: "Normal" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before,
.ql-snow
  .ql-picker.ql-size
  .ql-picker-label.ql-active[data-value="1.5em"]::before {
  content: "Large" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]::before,
.ql-snow
  .ql-picker.ql-size
  .ql-picker-label.ql-active[data-value="2.5em"]::before {
  content: "Huge" !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: attr(data-value) !important;
}

$text-editor-fonts: "GothamNarrowBook", "roboto", "cursive", "fantasy",
  "monospace";

@each $text-editor-font in $text-editor-fonts {
  .ql-font-#{$text-editor-font} {
    font-family: $text-editor-font;
  }
}

.ql-editor {
  font-family: "GothamNarrowBook";
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

.ql-container.ql-disabled {
  background-color: $background-disabled;
  color: $text-disabled;
  cursor: not-allowed;
}

quill-view {
  .ql-container.ql-disabled {
    background-color: initial;
    height: auto;
    color: initial;
  }

  .ql-container.ql-disabled .ql-editor {
    opacity: 1;
  }

  .ql-editor {
    height: auto;
    max-height: none;
  }
}
