@import "/src/app/styles/mixins";
@import "/src/app/styles/variables";

$dialog-padding: 40px;

@mixin dialog-fit-screen {
  max-width: calc(100vw - 2 * #{$dialog-padding});
  max-height: calc(100vh - 2 * #{$dialog-padding});
}

@mixin adap-dialog {
  @include dialog-fit-screen();
  border-radius: 0px;
  position: relative;
}

@mixin adap-dialog-title {
  @include adap-text-GothamBookMedium("big");
  color: $blue;
  flex-grow: 1;
  align-self: center;
}

@mixin adap-dialog-config($width: null, $height: null) {
  .mat-mdc-dialog-container {
    @if ($width) {
      width: $width;
    }
    @if ($height) {
      height: $height;
    }
    @include adap-dialog();
  }

  .mat-mdc-dialog-content {
    max-height: initial;

    .mat-mdc-dialog-content {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.new-workplan-dialog-config {
  @include adap-dialog-config(800px, 768px);
}

.chapter-manager-peer-pool-request-details-dialog-config {
  @include adap-dialog-config(750px, 650px);
}

.edit-project-dialog-config {
  @include adap-dialog-config(800px, 520px);
}

.edit-project-deliverable-dialog-config {
  @include adap-dialog-config(800px, 560px);
}

.da-deliverable-dialog-config {
  @include adap-dialog-config(880px);
}

.project-new-workplan-dialog-config,
.edit-workplan-dialog-config {
  @include adap-dialog-config(800px, auto);
}

.peer-pool-discipline-details-dialog-config,
.accept-discipline-dialog-config {
  @include adap-dialog-config(750px);
}

.sme-appointments-my-projects-dialog-config {
  @include adap-dialog-config(750px, 600px);
}

.observation-tool-warning-dialog-config {
  @include adap-dialog-config(580px);

  .mat-mdc-dialog-container {
    display: flex;
  }
}

.import-excel-dialog-config {
  @include adap-dialog-config(500px, 450px);
}

.change-chapter-manager-discipline-dialog-config {
  @include adap-dialog-config(530px);
}

.check-form-dialog {
  @include adap-dialog-config(800px, 610px);
}

.mandatory-check-form-dialog {
  @include adap-dialog-config(930px, 610px);
}

.mandatory-check-template-form-dialog {
  @include adap-dialog-config(800px, 400px);
}

.global-admin-access-dialog {
  @include adap-dialog-config(800px, 320px);
}

.generic-action-dialog .mat-mdc-dialog-container {
  @include adap-dialog();
  max-height: initial;
  width: 400px;
}

.one-on-one-match-dialog,
.automatch-dialog {
  @include adap-dialog-config(1100px, 700px);
}

.da-deliverables-details-dialog-config {
  @include adap-dialog-config(750px, 550px);
}

.generic-form-dialog {
  .input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    .adap-form-input {
      width: 100%;
      padding: 10px 5px;
    }

    .field-invalid-text {
      align-self: flex-start;
      color: $burgundy;
    }
  }
  .mat-mdc-dialog-container {
    @include adap-dialog();
    max-height: initial;
  }

  &.daTime-form-dialog {
    width: 350px;
  }

  &.billing-rate-usd-form {
    width: 450px;
  }

  &.sme-hours-dialog {
    height: 300px;
  }

  &.nickname-dialog {
    height: 350px;
    width: 600px;
  }
  &.flag-deliverable-dialog {
    width: 700px;
  }
  &.add-flag-deliverable-dialog {
    width: 500px;
  }

  &.deliverable-disciplines-value-dialog {
    width: 600px;
  }
}

.load-documents-dialog {
  @include adap-dialog-config(1050px, 750px);
}

.observation-tool-observation-dialog {
  @include adap-dialog-config(1300px, 750px);
}

.da-engineer-observation-tool-one-to-one-assign-dialog-config,
.project-contact-observation-tool-one-to-one-assign-dialog-config {
  @include adap-dialog-config(1580px, 650px);
}

.sme-observation-tool-disciplines-review-dialog-config {
  @include adap-dialog-config(800px, 700px);
}

.project-deliverables-import-duplicates-dialog {
  @include adap-dialog-config(600px, 500px);
}

.ipims-domain-assign-dialog {
  @include adap-dialog-config(600px, 750px);
}

.observation-form-complete-dialog,
.observation-form-attachment-dialog {
  @include adap-dialog-config(800px, 700px);
}

.observation-tool-check-details-dialog {
  @include adap-dialog-config(800px, 700px);
}

.readiness-check-pending-dialog {
  @include adap-dialog-config(1000px, 700px);
}

.readiness-check-closing-phase-dialog {
  @include adap-dialog-config(1000px, 750px);
}

.override-completion-dialog {
  @include adap-dialog-config(700px, 500px);
}

.other-da-deliverables-dialog {
  @include adap-dialog-config(930px, 610px);
}

.risk-assessment-downgrading-ranking-dialog .mat-mdc-dialog-container {
  width: 700px;
}

.focus-area-dialog-config {
  @include adap-dialog-config(600px, 500px);
}

.risk-assessment-untag-deliverable-dialog .mat-mdc-dialog-container {
  width: 500px;
}

.global-settings-value-recognition-dialog {
  @include adap-dialog-config(1200px, 650px);
}

.observation-tool-metrics-value-recognition-basis-dialog {
  @include adap-dialog-config(1200px, 600px);
}

.observation-returns-details-dialog-config {
  @include adap-dialog-config(1200px, 400px);
}
