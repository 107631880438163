@use "sass:math";
@import "./variables.scss";
@mixin adap-text-base($size) {
  font-weight: normal;

  @if type-of($size) == number {
    font-size: $size + px;
  }

  @if $size == "huge" {
    font-size: 30px;
  }

  @if $size == "big" {
    font-size: 24px;
  }

  @if $size == "normal+" {
    font-size: 20px;
  }

  @if $size == "normal" {
    font-size: 16px;
  }

  @if $size == "small+" {
    font-size: 14px;
  }

  @if $size == "small" {
    font-size: 12px;
  }
}

@mixin adap-text-GothamBookMedium($font-size) {
  @include adap-text-base($font-size);
  font-family: GothamBookMedium, sans-serif;
}

@mixin adap-text-GothamBold($font-size) {
  @include adap-text-base($font-size);
  font-family: GothamBold, sans-serif;
}

@mixin adap-text-GothamNarrowBook($font-size) {
  @include adap-text-base($font-size);
  font-family: GothamNarrowBook, sans-serif;
}

@mixin adap-text-GothamBook($font-size) {
  @include adap-text-base($font-size);
  font-family: GothamBook, sans-serif;
}

@mixin adap-icon-btn-base {
  border: none;
  cursor: pointer;

  @include adap-btn-disabled($gray, $white);
}

@mixin adap-btn-base {
  @include adap-text-GothamNarrowBook("normal");
  display: inline-flex;
  align-items: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  min-height: 30px;
  font-weight: bold;
  line-height: 22px;
  font-family: GothamBook, sans-serif;
  padding: 8px 20px;

  @at-root a#{&} {
    text-decoration: none;
  }

  &.minw250 {
    min-width: 250px;
  }

  &.minw200 {
    min-width: 200px;
  }

  &.minw150 {
    min-width: 150px;
  }

  &.minw100 {
    min-width: 100px;
  }

  &.minw50 {
    min-width: 50px;
  }

  &.minh40 {
    min-height: 40px;
  }

  &:focus {
    outline: none;
  }

  & mat-icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
}

// Primary button according to PICO
@mixin adap-btn-filled {
  @include adap-btn-base();

  color: $white;
  border: 2px solid transparent;
  background-color: $blue;

  &:hover {
    background-color: $blue-dark;
  }

  @include adap-btn-disabled($gray, $white);
}

// Secondary button according to PICO
@mixin adap-btn-outline {
  @include adap-btn-base();

  color: $blue;
  border: 2px solid $blue;
  background-color: $white;

  &:hover {
    border-color: $blue-dark;
    color: $blue-dark;
  }

  @include adap-btn-disabled($gray, $white);

  &:disabled {
    border-color: $gray;
  }
}

// Small button dimensions and font from PICO
@mixin adap-btn-small {
  @include adap-text-GothamNarrowBook("small+");
  max-height: 30px;
  justify-content: center;
  line-height: 30px;
  padding: 8px 16px;
}

@mixin adap-btn-small-icon {
  max-height: 32px;
  max-width: 32;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

@mixin adap-btn-grid-cell {
  @include adap-btn-base();

  background-color: transparent;
  color: $blue;
  padding: 0;
  line-height: initial;

  &:hover {
    color: $blue-dark;
  }

  @include adap-btn-disabled(transparent, $gray-dark);
}

@mixin adap-btn-blank {
  @include adap-btn-base();

  background-color: transparent;
  color: $blue;

  &.active {
    color: $blue-dark;
    background-color: #f7f7f7;
  }

  &.margin-border {
    border: 2px solid $blue;
    margin: 5px 15px 5px 0;
  }

  &.selected {
    color: $white;
    background-color: $blue;
  }

  @include adap-btn-disabled(transparent, $gray);
}

@mixin adap-btn-warning {
  @include adap-btn-base();

  background-color: $red;
  color: $white;

  &:hover {
    background-color: $burgundy;
  }

  @include adap-btn-disabled($gray, $white);
}

@mixin adap-btn-observation-tool($btn-color) {
  @include adap-btn-base();

  border: 1px solid;
  background-color: $white;
  border-color: $btn-color;
  color: $btn-color;
  border-radius: 5px;
  line-height: initial;
  padding: 2px 10px;

  &:hover,
  &.selected {
    color: $white;
    background-color: $btn-color;
  }
}

@mixin adap-btn-ready {
  @include adap-btn-observation-tool($blue-dark);
}

@mixin adap-btn-observations {
  @include adap-btn-observation-tool($blue);
}

@mixin adap-btn-actions {
  @include adap-btn-observation-tool($teal);
}

@mixin adap-btn-reviewed {
  @include adap-btn-observation-tool($green-dark);
}

@mixin adap-btn-v-and-v {
  @include adap-btn-observation-tool($burgundy);
}

@mixin adap-btn-disabled($btn-background-color, $btn-font-color) {
  &:disabled {
    cursor: default;
    pointer-events: none;
    background-color: $btn-background-color;
    color: $btn-font-color;
  }
}

@mixin adap-corner {
  border-radius: 0px 0 0 0px !important;
}

@mixin adap-mat-form-field-empty {
  color: $gray-dark;
}

@mixin adap-mat-form-field-arrow {
  transform: initial;
  &::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-left: 2px solid $blue;
    border-bottom: 2px solid $blue;
    transform: translate(-100%, -25%) rotate(-45deg);
  }
}

@mixin adap-mat-form-field-filled {
  color: $black;
}

@mixin adap-mat-form-field-border {
  opacity: 1;
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    border: none;
  }
}

@mixin adap-mat-form-field-active {
  color: $blue;
}

@mixin adap-mat-form-field-text-disabled {
  color: $text-disabled;
}

@mixin adap-mat-form-field-disabled {
  border-color: $border-disabled;
  background-color: $background-disabled;
}

@mixin adap-form-input {
  padding: 5px;
  border: 2px solid $gray;
  color: $black-dark;
  outline: none;
  border-radius: 0;
  transition: border-color 0.2s ease;

  &[disabled] {
    @include adap-mat-form-field-disabled;
    @include adap-mat-form-field-text-disabled;
  }

  &.warning {
    border-color: $orange;
  }

  &:focus {
    border-color: $blue;
  }

  &::placeholder {
    color: $gray-primary;
    opacity: 1;
  }
}

@mixin adap-form-textarea {
  @include adap-form-input;
}

@mixin adap-form-container {
  .field-invalid-text {
    @include field-invalid-text;
  }
}

@mixin adap-box-shadow {
  box-shadow: 0px 3px 6px $card-shadow-color;
}

@mixin adap-card {
  background-color: $white;
  padding: 40px;
  margin: 20px 0;
  @include adap-box-shadow;
}

@mixin adap-page-layout {
  width: 1400px;
  max-width: 100%;
}

@mixin adap-page-layout-card {
  @include adap-page-layout;
  @include adap-card();
  flex: 1;
}

@mixin adap-screening-inner-section-layout {
  display: flex;
  gap: 10px;
}

@mixin adap-screening-section-layout {
  @include adap-card();
  @include adap-screening-inner-section-layout();
}

@mixin mat-form-field-reset {
  .mat-form-field-wrapper .mat-mdc-text-field-wrapper {
    margin: 0 0;
    padding-bottom: 0;
  }

  .mdc-text-field {
    padding-left: 0;
    padding-right: 0;
  }

  .mat-mdc-form-field-infix {
    border-top: 0;
    padding: 7px 0 7px 0;
    align-content: center;
    z-index: 10;
  }

  .mat-mdc-form-field-flex {
    margin-top: 0;
    padding: 0 7px 0 7px;
    align-items: center;
    background-color: field;
    max-height: 38px;
  }

  .mat-mdc-form-field-text-suffix {
    top: 0;
  }

  .mat-mdc-form-field-outline {
    top: 0;
  }
}

@mixin adap-mat-form-field-outline {
  &:not(.mat-focused):not(.mat-form-field-disabled) .mdc-notched-outline {
    @include adap-mat-form-field-outline-invalid;
  }

  &:not(.mat-focused):not(.mat-form-field-invalid):not(.mat-form-field-disabled)
    .mdc-notched-outline {
    border: 2px solid $gray;
    @include adap-mat-form-field-border;
  }

  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end,
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border: none;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  &:not(.mat-focused):not(.mat-form-field-invalid):not(
      .mat-form-field-disabled
    ):not(:has(input[disabled]))
    .mat-mdc-form-field-flex:hover
    .mdc-notched-outline {
    border: 2px solid $black-dark;
    @include adap-mat-form-field-border;
  }

  &:is(.mat-focused) .mdc-notched-outline {
    @include adap-mat-form-field-active;
    border: 2px solid $blue;
  }

  &.mat-form-field-disabled {
    .mat-mdc-form-field-flex .mdc-notched-outline,
    .mat-mdc-form-field-flex .mat-mdc-form-field-outline {
      border: 2px solid;
      @include adap-mat-form-field-disabled;
    }
    .mat-mdc-select-value-text {
      @include adap-mat-form-field-text-disabled;
    }
    .mat-select-value .mat-mdc-select-placeholder {
      @include adap-mat-form-field-text-disabled;
    }
  }
}

@mixin adap-form-field-outline-corner {
  .mat-form-field-outline-start {
    @include adap-corner;
  }
  .mat-form-field-outline-end {
    @include adap-corner;
  }
}

@mixin adap-datepicker {
  .mat-mdc-select {
    @include adap-text-GothamNarrowBook("normal");
  }

  &[disabled] {
    @include adap-mat-form-field-text-disabled;
    @include adap-mat-form-field-disabled;
  }

  &.mat-form-field-appearance-outline {
    @include mat-form-field-reset;
    @include adap-mat-form-field-outline;
    @include adap-form-field-outline-corner;

    &:has(.mat-mdc-form-field-infix):has(input[disabled]) {
      .mat-mdc-form-field-flex .mat-mdc-form-field-outline {
        border: 2px solid;
        @include adap-mat-form-field-disabled;
      }

      [matInput] {
        @include adap-mat-form-field-text-disabled;
      }
    }
  }
}

@mixin adap-form-range-input {
  @include adap-datepicker;
  color: red;
  background-color: purple;
}

@mixin adap-select {
  .mat-mdc-select {
    @include adap-text-GothamNarrowBook("normal");
    &[disabled] {
      @include adap-mat-form-field-text-disabled;
      @include adap-mat-form-field-disabled;
    }
  }

  &.mat-form-field-appearance-outline .mat-mdc-select-arrow-wrapper {
    @include adap-mat-form-field-arrow;
  }

  .mat-mdc-select-arrow {
    display: none !important;
  }

  .mat-mdc-select-placeholder {
    @include adap-mat-form-field-empty;
    @include adap-text-GothamNarrowBook("normal");
    color: $gray-primary !important;
  }
  .mat-mdc-select-value-text {
    @include adap-mat-form-field-filled;
  }

  &.mat-mdc-form-field {
    width: 100%;
    line-height: 24px;
  }

  &.mat-form-field-appearance-outline {
    @include mat-form-field-reset;
    @include adap-mat-form-field-outline;
    @include adap-form-field-outline-corner;
  }
}

@mixin adap-radio {
  @at-root input[type="radio"]#{&} {
    appearance: none;
    margin: 0;
    font: inherit;
    color: $blue;
    width: 1em;
    height: 1em;
    transform: translateY(0.25em);
    border: max(2px, 0.15em) solid $blue;
    border-radius: 50%;
    display: grid;
    place-content: center;

    &::before {
      width: 0.5em;
      height: 0.5em;
      border-radius: 50%;
      transform: scale(0);
      transition: 0.1s transform ease;
      box-shadow: inset 1em 1em $blue;
      content: "";
    }

    &:disabled {
      border-color: $gray;
    }

    &:disabled::before {
      box-shadow: inset 1em 1em $gray;
    }

    &:checked::before {
      transform: scale(1);
    }

    &:focus-visible {
      outline: max(2px, 0.15em) solid $blue;
      outline-offset: max(2px, 0.15em);
    }
  }
}

@mixin table-full-height {
  :host {
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
  }

  ag-grid-angular {
    flex: 1;
    margin-top: 20px;
  }
}

@mixin table-fullscreen-options {
  .actions {
    display: flex;
  }

  app-ag-grid-options {
    flex: 1;
  }
}

@mixin mat-icon-size($size) {
  font-size: $size;
  width: $size;
  height: $size;
}

@mixin adap-btn-toggle-group {
  $size: 15px;
  $height: 40px;
  &.mat-button-toggle-group-appearance-standard {
    border: 0;
  }
  .mat-button-toggle-appearance-standard {
    color: $blue !important;
    border: 2px solid $blue !important;
    height: $height;
    width: $height;
    align-items: center;

    &:first-child {
      border-radius: $size 0 0 $size;
    }
    &:last-child {
      border-radius: 0 $size $size 0;
    }

    &:hover {
      border-color: $blue-dark !important;
      color: $blue-dark !important;
    }
  }
  .mat-button-toggle-checked {
    background-color: $blue !important;
    color: $white !important;
    &:hover {
      border-color: $blue-dark;
      background-color: $blue-dark;
      color: $white;
    }
  }

  .mat-icon {
    margin-bottom: $size;
    padding-right: math.div($size, 2);
    transform: scale(1.5);
  }

  .mat-button-toggle .mat-pseudo-checkbox {
    display: none;
  }
}

@mixin hidden {
  display: none;
}

@mixin decoration-none {
  &,
  &:link,
  &:visited,
  &:hover {
    text-decoration: none;
  }
}

@mixin main-container-sticky {
  @include container-sticky;

  &::before {
    background: $gray-light;
  }
}

@mixin white-container-sticky {
  @include container-sticky;
  background: $white;

  &::before {
    background: $white;
  }
}

@mixin container-sticky {
  position: sticky;
  top: 20px;
  z-index: 1;

  &::before {
    content: "";
    z-index: -2;
    position: absolute;
    width: calc(100% + 10px);
    height: calc(100% + 20px);
    top: -20px;
    left: -5px;
  }

  &::after {
    content: "";
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $white;
  }

  .fullscreen-wrapper &::before {
    height: calc(100% + 28px);
    top: -28px;
  }
}

@mixin relative-time {
  display: block;
  color: $gray-dark;
}

@mixin adap-title {
  @include adap-text-GothamBold("big");
  color: $blue;
  margin-top: 5px;
}

@mixin header-title {
  color: $blue-dark;
  @include adap-text-GothamBold("big");
}

@mixin adap-dialog-content {
  display: flex !important;
  flex-direction: column;
  height: 100%;
  gap: 20px;
  color: $black !important;
  .dialog-actions {
    display: flex;
    gap: 10px;
    justify-content: end;
    margin-top: auto;
  }
}

@mixin adap-chip {
  @include adap-text-GothamBookMedium(14);
  text-align: center;
  border-radius: 5px;
  padding: 2px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
